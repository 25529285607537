/*--------------------------------------------------------*\
	If all posts haven't been loaded and filtering occurs,
	load the rest of the posts.
\*--------------------------------------------------------*/

import els from '../ref/els';
import balanceHeight from '../util/balanceHeight';

const checkPostsLoadPosts = (instance, listener = false) => {
	// need to abstract that 9 more
	if (
		instance.iso.items.length + instance.localItems.length >
		instance.postsLoaded + 9
	) {
		let { localItems: items, postsLoaded: index } = instance;
		let newItems = items.slice(index, items.length - index);
		instance.postsLoaded = items.length;
		instance.iso.insert(newItems);
		instance.iso.layout();

		if (!!listener) {
			listener.parentNode.removeChild(listener);
		}

		if (
			instance.iso.items.length + instance.localItems.length >
				instance.postsLoaded + 9 &&
			!!instance.loadMore &&
			!!instance.loadMore.parentNode
		) {
			instance.loadMore.parentNode.removeChild(instance.loadMore);
		}

		balanceHeight(els.isotope.media.items, () => {
			setTimeout(() => instance.iso.layout(), 50);
		});

		return true;
	}

	return false;
};

export default checkPostsLoadPosts;
