/*--------------------------------------------------------*\
	Isotope Filtering Function
	**** TODO: Abstract these functions ??
	**** TODO: Add hashes for all instances?
\*--------------------------------------------------------*/

import els from '../ref/els';
import includes from 'lodash.includes';
import toArray from 'lodash.toarray';
import _find from 'lodash.find';
import closest from './closest';
import permute from './permute';
import checkPostsLoadPosts from './checkPostsLoadPosts';

const isoFiltersInit = (instance, iso, filters) => {
	// Instantiate type of isotope instance
	filters =
		!!filters[0].querySelector('input') == true
			? document.querySelectorAll('.iso--filter input')
			: filters;
	let filterType =
		filters[0].nodeName === 'INPUT'
			? 'checkbox'
			: filters[0].classList.contains('menu__item')
			? 'menu'
			: 'dropdown';

	// Loop through Filters
	for (let filter of filters) {
		filter.addEventListener('click', (event) => {
			// Lost posts if all aren't loaded (only for blog)
			checkPostsLoadPosts(els.isotope.media);

			// Set filters to empty string
			let filters = '';

			// Begin filtering
			if (filterType === 'menu') {
				// Clear seach
				let target = event.target;

				// Clear all filters, add active filter class
				for (let filter of els.isotope.filters)
					filter.classList.remove('filter--active');
				target.classList.add('filter--active');

				// Get the filter
				let filter = target.getAttribute('data-filter');

				// Run the filter, layout
				if (filter.indexOf('.') > -1) {
					iso.arrange({ filter: filter });
				} else {
					iso.arrange({ filter: '*' });
				}

				iso.layout();
			}
		});
	}
};

export default isoFiltersInit;
