import els from '../ref/els';
import hoverintent from 'hoverintent';

/*--------------------------------------------------------*\
	Dropdown
\*--------------------------------------------------------*/

const submenuInit = (items) => {
	// hoverintent config
	let config = {
		sensitivity: 3,
		interval: 100,
		timeout: 0,
	};

	for (let item of items) {
		hoverintent(
			item,
			function () {
				this.querySelector('.sub-menu').style.visibility = 'visible';
			},
			function () {
				this.querySelector('.sub-menu').style.visibility = 'hidden';
			}
		).options(config);
	}
};

/*--------------------------------------------------------*\
	Menu
\*--------------------------------------------------------*/

const toggleMenu = (menu) => {
	menu.addEventListener('click', function () {
		// Toggle menu
		this.classList.toggle('active');

		// Toggle overlay && header
		if (els.navMenu.classList.contains('overlay__menu--visible')) {
			els.navMenu.classList.remove('overlay__menu--visible');
			els.header.classList.remove('header--open');
		} else {
			els.navMenu.classList.add('overlay__menu--visible');
			els.header.classList.add('header--open');
		}
	});
};

export default () => {
	toggleMenu(els.navToggle);
	submenuInit(els.submenus);
};
