/*--------------------------------------------------------*\
	Get required posts and store them in localStorage
	
	**** TODO: Add better error handling for failed load
	**** TODO: Only refresh/mutate localStorage postChange --> do this and simply append rather than re-fetching
\*--------------------------------------------------------*/

import request from 'superagent';
import Promise from 'promise';
import parsePosts from './parsePosts';
import els from '../ref/els';

const loadPosts = (action, storage, obj, callback) => {
	window.localStorage.clear();

	// Has data changed?
	let saved = JSON.parse(window.localStorage.getItem(storage));
	let currentTime = new Date().getTime();
	let lastSaved = saved === null ? 0 : saved.time;
	let dt = (currentTime - lastSaved) / (1000 * 60);

	// request
	let req = new Promise((resolve, reject) => {
		// if it's been less than 30 mins, don't make a new request
		if (dt >= 30) {
			request
				.post(ajaxurl)
				.send({ action })
				.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
				.end((err, res) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.text);
					}
				});
		} else {
			resolve(false);
		}
	});

	// Resolve
	req
		.then((res) => {
			// Maybe I should convert this to a List to check exact equality???
			if (res) {
				// Get current data if request is made
				let current = JSON.parse(res);

				// Don't update if data has not changed
				if (saved === null || saved.length !== current.length) {
					const data = {
						posts: current,
						time: currentTime,
					};

					// Set localStorage
					window.localStorage.setItem(storage, JSON.stringify(data));
				}
			}

			// Parse posts and send to { els } either way
			parsePosts(storage, obj);

			// Callbacks
		})
		.then(() => {
			if (!!callback) {
				callback();
			}
		});
};

export default loadPosts;
