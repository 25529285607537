/*--------------------------------------------------------*\
	Google Map
\*--------------------------------------------------------*/

let map = {
	map: document.getElementById('map-canvas'),

	mapInit() {
		let styles = [
			{
				featureType: 'all',
				elementType: 'all',
				stylers: [{ visibility: 'on' }, { hue: '#a5c867' }],
			},
			{
				featureType: 'water',
				elementType: 'all',
				stylers: [{ color: '#54a1aa' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#669933' }],
			},
		];

		let styledMap = new google.maps.StyledMapType({
			styles,
			name: 'Styled Map',
		});

		let mapOptions = {
			center: { lat: 38.257456, lng: -85.760727 },
			zoom: 15,
			mapTypeControlOptions: {
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style'],
			},
			scrollwheel: false,
		};

		let map = new google.maps.Map(
			document.getElementById('map-canvas'),
			mapOptions
		);

		let marker = new google.maps.Marker({
			position: { lat: 38.257456, lng: -85.760727 },
			map: map,
		});

		map.mapTypes.set('map_style', styledMap);
		map.setMapTypeId('map_style');
	},

	init() {
		if (!!this.map) {
			google.maps.event.addDomListener(window, 'load', this.mapInit);
		}
	},
};

export default () => {
	if (!!map.map) {
		map.init();
	}
};
