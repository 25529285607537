/*--------------------------------------------------------*\
	Load More Posts
\*--------------------------------------------------------*/

import els from '../ref/els';
import Isotope from 'isotope-layout';

const loadMore = (element, instance, callback) => {
	return element.addEventListener('click', () => {
		let items = instance.localItems;
		let index = instance.postsLoaded;
		let newItems = items.slice(index, index + 6);
		instance.postsLoaded += 6;
		instance.iso.insert(newItems);

		// Remove load more when there's no more posts to load
		if (
			instance.postsLoaded + 6 >= instance.localItems.length &&
			!!instance.loadMore
		) {
			instance.loadMore.parentNode.removeChild(instance.loadMore);
		}

		// Callback
		if (!!callback) {
			callback();
		}
	});
};

export default loadMore;
