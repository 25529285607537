/*--------------------------------------------------------*\
	Globally defined reference && state structures
\*--------------------------------------------------------*/

import _parseInt from 'lodash.parseint';

const els = {
	// Mains
	wrapper: document.getElementsByClassName('wrapper')[0],
	head: document.getElementsByTagName('head')[0],
	header: document.getElementsByClassName('header')[0],
	submenuParents: document.querySelectorAll(
		'.nav--main > .menu-item-has-children'
	),
	submenus: document.querySelectorAll('.nav .menu-item-has-children'),
	navSubmenus: document.getElementsByClassName('nav__submenus')[0],
	navToggle: document.getElementsByClassName('nav__toggle')[0],
	navMenu: document.getElementsByClassName('overlay__menu')[0],
	footer: document.getElementsByClassName('footer')[0],
	blocks: document.getElementsByClassName('block'),
	content: document.getElementsByClassName('content'),

	// Banner
	bannerItems: document.getElementsByClassName('banner__item'),

	// events
	events: {
		slider: document.getElementById('slider__events'),
		items: document.getElementsByClassName('event__item'),
		sizing: {
			width: null,
			height: null,
		},
	},

	// modal
	modal: {
		overlay: document.getElementsByClassName('md-overlay')[0],
	},

	// isotope
	isotope: {
		media: {
			container: document.getElementById('iso__media'),
			posts: null,
			postsLoaded: 0,
			localItems: null,
			loadMore: document.getElementsByClassName('iso__loadMore')[0],
			items: document.getElementsByClassName('media__item'),
			selector: '.media__item',
			gutter: 16,
			iso: null,
			filters: {},
			filter: '*',
			responsive: [
				{
					width: 672,
					gutter: 0,
				},
			],
		},
		filters: document.querySelectorAll('.iso--filter'),
	},

	// window.location
	protocol: window.location.protocol,
	host: window.location.host,
	pathname: window.location.pathname,
	hash: window.location.hash,
	ref: document.referrer,
};

export default els;
