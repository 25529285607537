/*--------------------------------------------------------*\
	Events
\*--------------------------------------------------------*/

import els from '../ref/els';
import _debounce from 'lodash.debounce';
import fastdom from 'fastdom';
import imagesloaded from 'imagesloaded';
import Promise from 'bluebird';

const slickEventsInit = (slider, arrows, slick, $) => {
	// init
	let slickInit = new Promise((resolve, reject) => {
		$(slider).slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: true,
			dots: false,
			resize_height: false,
			cssEase: 'linear',
			speed: 500,
			autoplay: true,
			autoplaySpeed: 5000,
			lazyLoad: false,
			responsive: [
				{
					breakpoint: 1025,
					settings: {
						slidesToShow: 2,
					},
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 1,
					},
				},
			],
		});

		resolve(true);
	});

	// replace button text with arrows
	slickInit.then(() => {
		// left
		document.getElementsByClassName(arrows.left)[0].innerHTML =
			'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"><path d="M88.6 121.3c.8.8 1.8 1.2 2.9 1.2s2.1-.4 2.9-1.2c1.6-1.6 1.6-4.2 0-5.8l-51-51 51-51c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8 0l-54 53.9c-1.6 1.6-1.6 4.2 0 5.8l54 53.9z"/></svg>';

		// right
		document.getElementsByClassName(arrows.right)[0].innerHTML =
			'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129 129"><path d="M40.4 121.3c-.8.8-1.8 1.2-2.9 1.2s-2.1-.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8 0l53.9 53.9c1.6 1.6 1.6 4.2 0 5.8l-53.9 53.9z"/></svg>';
	});
};

const eventItemSizing = (items, sizing, callback) => {
	fastdom.measure(() => {
		for (let item of items) {
			let height = item.offsetHeight;
			if (height === null) {
				sizing.height = height;
			} else if (height > sizing.height) {
				sizing.height = height;
			}
		}

		fastdom.mutate(() => {
			for (let item of items) {
				item.style.height = sizing.height + 'px';
			}
		});
	});

	if (!!callback && typeof callback === 'function') {
		return callback();
	}
};

if (els.events.items.length > 0) {
	new Promise((resolve) => {
		require.ensure([], () => {
			resolve({
				slick: require('slick-carousel'),
				$: require('jquery'),
			});
		});
	}).then(({ slick, $ }) => {
		imagesloaded(els.events.items, { background: '.event__image' }, () => {
			// after images are loaded, loop through heights
			eventItemSizing(
				els.events.items,
				els.events.sizing,
				slickEventsInit.bind(
					null,
					els.events.slider,
					{
						left: 'slick-prev',
						right: 'slick-next',
					},
					slick,
					$
				)
			);

			window.addEventListener(
				'resize',
				_debounce(() => eventItemSizing(els.events.items, els.events.sizing))
			);
		});
	});
}
