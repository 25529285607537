/*--------------------------------------------------------*\
	Resize Handler for Isotope
\*--------------------------------------------------------*/

import _debounce from 'lodash.debounce';

const isotopeResize = _debounce((instance, options) => {
	let opts = options.responsive;
	for (let option of opts) {
		if (window.innerWidth <= option.width) {
			instance.options.masonry.gutter = option.gutter;
			instance.layout();
		}
	}
}, 150);

export default isotopeResize;
