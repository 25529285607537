/*--------------------------------------------------------*\
	Instantiate Isotope Instance
\*--------------------------------------------------------*/

import els from '../ref/els';
import Isotope from 'isotope-layout';
import _debounce from 'lodash.debounce';
import isotopeResize from './isotopeResize';
import masontryHorizontal from 'isotope-masonry-horizontal';

const isoInit = (options, callback) => {
	// Main options
	let iso = new Isotope(options.container, {
		itemSelector: options.selector,
		masonry: {
			columnWidth: options.selector,
			gutter: options.gutter,
		},
		transitionDuration: '0.25s',
	});

	// Responsive
	if (!!options.responsive) {
		if (window.innerWidth <= 1168) isotopeResize(iso, options);
		window.addEventListener('resize', () => isotopeResize(iso, options));
	}

	// Initialize filter/sort if no hash exists
	if (!!options.filter && els.hash === '') {
		iso.arrange({
			filter: options.filter,
		});
	}

	// Filter by hash
	if (els.hash !== '') {
		let filter = 'filter--' + els.hash.split('#')[1];
		document
			.querySelector(`.menu__item[data-filter=".${filter}"]`)
			.classList.add('filter--active');
		iso.arrange({ filter: '.' + filter });
		iso.layout();
	}

	// Initialize layout on first call
	iso.layout();

	// Save and return the object
	options.iso = iso;
	return iso;
};

export default isoInit;
