/*--------------------------------------------------------*\
	Styles
\*--------------------------------------------------------*/
import '../../styl/src/screen.scss';
import 'kbd-modal/styl/modal.scss';

/*--------------------------------------------------------*\
	Requires
\*--------------------------------------------------------*/

import _parseInt from 'lodash.parseint';
import els from './ref/els';
import header from './lib/header';
import banner from './lib/banner';
import isoInit from './util/isotope';
import map from './lib/map';
import fastdom from 'fastdom';
import isoFiltersInit from './util/isotopeFilters';
import balanceHeight from './util/balanceHeight';
import _debounce from 'lodash.debounce';
import loadMore from './util/loadMore';
import loadPosts from './util/loadPosts';
import events from './lib/events';

header();
map();

if (!!els.isotope.media.container) {
	loadPosts('get__media_action', 'ffa__mediaItems', els.isotope.media, () => {
		balanceHeight(els.isotope.media.items, () => {
			setTimeout(() => iso.layout(), 50);
		});
		window.addEventListener('resize', () =>
			_debounce(balanceHeight(els.isotope.media.items), 150)
		);
		const instance = els.isotope.media;
		const iso = isoInit(instance);

		isoFiltersInit(instance, iso, els.isotope.filters);
		loadMore(els.isotope.media.loadMore, instance, () => {
			balanceHeight(els.isotope.media.items, () => {
				setTimeout(() => iso.layout(), 50);
			});
		});
	});
}

/*--------------------------------------------------------*\
	Placeholders
\*--------------------------------------------------------*/

import placeholder from './util/placeholder';

let labels = document.querySelectorAll('.ginput_container label');
placeholder(labels);

/*--------------------------------------------------------*\
	Image Alignment
\*--------------------------------------------------------*/

function alignImages() {
	for (let content of els.content) {
		let images = content.querySelectorAll('img');
		if (images.length > 0) {
			for (let image of images) {
				let parent = image.parentNode;

				// get alignment
				let alignment = null;
				if (image.classList.contains('alignleft')) {
					alignment = 'alignleft';
				} else if (image.classList.contains('alignright')) {
					alignment = 'alignright';
				} else if (image.classList.contains('aligncenter')) {
					alignment = 'aligncenter';
				}

				// apply alignment
				parent.classList.add(`image--${alignment}`);
			}
		}
	}
}

if (els.content.length > 0) {
	alignImages();
}

/*--------------------------------------------------------*\
	Pad first block
\*--------------------------------------------------------*/

const padFirstBlock = () => {
	let block = els.blocks[0];

	fastdom.measure(() => {
		let height = els.header.offsetHeight;
		let padding = _parseInt(window.getComputedStyle(block).paddingTop, 10);

		if (block.classList.contains('block--banner')) {
			let bannerHeight = _parseInt(window.getComputedStyle(block).height, 10);

			fastdom.mutate(() => {
				block.style.height = bannerHeight + height + 'px';
				block.style.paddingTop = height + padding + 'px';
			});
		} else {
			fastdom.mutate(() => {
				block.style.paddingTop = height + padding + 'px';
			});
		}
	});
};

padFirstBlock();

/*--------------------------------------------------------*\
    Cookie Functions
\*--------------------------------------------------------*/

function getCookie(cname) {
	var name = cname + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return false;
}
function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/*--------------------------------------------------------*\
	Load
\*--------------------------------------------------------*/

document.getElementsByClassName('wrapper')[0].style.opacity = 1;

(($) => {
	const showError = (msg) => {
		$('#error_message div').html(msg);
		$('#success_message').hide();
		$('#error_message').show();
	};

	const slidein_showError = (msg) => {
		$('#slidein_error_message').text(msg);
		$('#slidein_success_message').hide();
		$('#slidein_error_message').css('display', 'inline-block');
	};

	const showSuccess = () => {
		$('#error_message').hide();
		$('#embedded_signup').hide();
		$('#success_message').show();
	};

	const slidein_showSuccess = () => {
		$('#slidein_error_message').hide();
		$('#slidein_embedded_signup').hide();
		$('#slidein_success_message').css('display', 'inline-block');
		$('#slidein_embedded_signup #ctct-embed-signup').hide();

		setTimeout(function () {
			$('.slide_in_email').removeClass('add_slide_in');
			setCookie('noemailslider', 'hidden', 365);
		}, 3000);
	};

	$('#embedded_signup').on('submit', (e) => {
		e.preventDefault();

		$('#success_message,#error_message,#embedded_submit').hide();
		$('#embedded_spinner').show();

		$.post(
			'/wp-json/ffta/v1/signup',
			{
				email: $('#embedded_email').val(),
				honeypot: $('#honeypot_email').val(),
			},
			(data) => {
				$('#embedded_spinner').hide();
				$('#embedded_submit').show();

				if (data.status == 'err') {
					showError(data.msg);
				} else {
					showSuccess();
				}
			}
		);

		return false;
	});

	$('#slidein_embedded_signup').on('submit', (e) => {
		e.preventDefault();

		$(
			'#slidein_success_message,#slidein_error_message,#slidein_embedded_submit'
		).hide();
		$('#slidein_embedded_spinner').show();

		$.post(
			'/wp-json/ffta/v1/signup',
			{
				email: $('#slidein_embedded_email').val(),
				honeypot: $('#slidein_honeypot_email').val(),
			},
			(data) => {
				$('#slidein_embedded_spinner').hide();
				$('#slidein_embedded_submit').show();

				if (data.status == 'err') {
					slidein_showError(data.msg);
				} else {
					slidein_showSuccess();
				}
			}
		);

		return false;
	});

	if ($('.slide_in_email').length > 0) {
		if (!getCookie('noemailslider')) {
			setTimeout(function () {
				$('.slide_in_email').addClass('add_slide_in');
			}, 1500);

			$('.slide_in_email .close').click(function () {
				setCookie('noemailslider', 'hidden', 365);
				$('.slide_in_email').removeClass('add_slide_in');
			});
		}
	}

	prepareScrollHiding();

	// hide header & obscure donate button when scrolling down
	// reopen them when scrolling back up
	function prepareScrollHiding() {
		let didScroll;
		let lastScrollTop = 0;
		const delta = 5;
		const $navbar = $('header.header');
		const navbarHeight = $navbar.outerHeight();
		const $giveBtn = $('.give__button');

		$(window).scroll(function (event) {
			didScroll = true;
		});

		setInterval(function () {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 250);

		function hasScrolled() {
			let st = $(window).scrollTop();

			// Make sure they scroll more than delta
			if (Math.abs(lastScrollTop - st) <= delta) {
				return;
			}

			// If they scrolled down and are past the navbar, add visible class
			if (st > lastScrollTop && st > navbarHeight) {
				// Scroll Down
				$navbar.removeClass('header--visible').addClass('header--hidden');
				$giveBtn.addClass('give__button--obscured');
			} else {
				// Scroll Up
				if (st + $(window).height() < $(document).height()) {
					$navbar.removeClass('header--hidden').addClass('header--visible');
					$giveBtn.removeClass('give__button--obscured');
				}
			}

			lastScrollTop = st;
		}
	}
})(jQuery);
