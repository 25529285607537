/*--------------------------------------------------------*\
	Set all elements to mirror the tallest element. 
	Loops through children to mitigate compunding 
	resizing height issues.

	*** TODO: Return binding event listener of self?
\*--------------------------------------------------------*/

import _parseInt from 'lodash.parseint';
import fastdom from 'fastdom';

const balanceHeight = (items, callback) => {
	// Which one is the tallest?
	let height = 0;
	let i = items.length;
	let j = i;

	fastdom.measure(() => {
		while (i--) {
			// Set element height to 0
			let itemHeight = {
				full: 0,
				padding: 0,
				childrenHeight: 0,
			};

			// Add padding from the main element
			itemHeight.padding =
				_parseInt(window.getComputedStyle(items[i]).paddingTop, 10) +
				_parseInt(window.getComputedStyle(items[i]).paddingBottom, 10);

			// Add Height of children
			for (let child of items[i].children) {
				if (window.getComputedStyle(child).position != 'absolute') {
					// Main Height
					itemHeight.childrenHeight += child.clientHeight;

					// Child Margin
					itemHeight.childrenHeight += _parseInt(
						window.getComputedStyle(child).marginTop,
						10
					);
					itemHeight.childrenHeight += _parseInt(
						window.getComputedStyle(child).marginBottom,
						10
					);
				}
			}

			itemHeight.full = itemHeight.padding + itemHeight.childrenHeight;
			if (itemHeight.full > height) height = itemHeight.full;
		}

		fastdom.mutate(() => {
			while (j--) {
				items[j].style.height = height + 'px';
			}
		});
	});

	if (!!callback && typeof callback === 'function') {
		callback();
	}
};

export default balanceHeight;
