/*--------------------------------------------------------*\
	Banner
\*--------------------------------------------------------*/

import els from '../ref/els';
import kbdSlider from 'kbd-slidr';

const slider = (items, sliderItems = []) => {
	const getItems = (items) => {
		for (let item of items) {
			let data = item.getAttribute('data-slidr');
			sliderItems.push(data);
		}
		sliderItems.push('one');
	};

	const sliderInit = () => {
		let KBDSlider = kbdSlider
			.create('banner__slider', {
				breadcrumbs: false,
				controls: false,
				transition: 'fade',
			})
			.add('h', sliderItems)
			.auto(10000);
	};

	const lazyload = (items) => {
		for (let item of items) {
			let url = item.getAttribute('data-bg');
			let image = new Image();
			image.onload = function () {
				item.style.backgroundImage = `url('${url}')`;
			};
			image.src = url;
		}
	};

	return ((items) => {
		if (items.length > 1) {
			getItems(items);
			sliderInit();
			lazyload(items);
		}
	})(items);
};

if (els.bannerItems.length > 0) {
	slider(els.bannerItems);
}
